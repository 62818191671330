<template>
  <div class="panel">
    <div class="list">
      <div class="title" style="display: flex;justify-content: space-between;align-items: baseline;">
        <div>
          <span class="icon" style="display: inline-block;vertical-align: middle;"></span>
          <span style="display: inline-block;vertical-align: middle;">智能单体清单</span>
        </div>
        <div v-if="isAdmin">
          <input ref="uploader" id="uploader" type="file" @change="onChange" />
          <a-button @click="onClick" :loading="importLoading" type="primary"
            >导入
          </a-button>
        </div>
      </div>

      <a-skeleton v-if="loading" />
      <div v-else style="max-height: 75vh;overflow-x: hidden;overflow-y: auto;">
        <div
          class="item"
          v-for="item in menu"
          :key="item.id"
          @click="selectedId = item.id"
          :class="selectedId === item.id ? 'active' : ''"
        >
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="title">
        <span class="icon"></span>
        <span>设计参数</span>
      </div>

      <a-skeleton v-if="detailLoading" />

      <div v-else>
        <table class="table">
          <tr class="header">
            <td style="flex: 1">中文名</td>
            <td style="width: 220px">设计值</td>
            <td style="width: 120px; text-align: center">单位</td>
            <td style="width: 120px; text-align: center">类型</td>
            <td style="width: 240px">参考信息</td>
          </tr>
          <tr class="row" v-for="item in list" :key="item.id">
            <td style="flex: 1">{{ item.name }}</td>
            <td style="width: 220px">
              <a-tooltip>
                <template slot="title">
                  {{ item.designValue }}
                </template>
                <div style="overflow: hidden;-webkit-line-clamp: 2;text-overflow: ellipsis;display: -webkit-box;-webkit-box-orient: vertical;">{{ item.designValue }}</div>
              </a-tooltip>
            </td>
            <td style="width: 120px; text-align: center">{{ item.unit }}</td>
            <td style="width: 120px; text-align: center">
              <span v-if="item.valueClass === '1'">数值</span>
              <span v-if="item.valueClass === '2'">公式</span>
              <span v-if="item.valueClass === '3'">公式</span>
            </td>
            <td style="width: 240px">{{ item.remark }}</td>
          </tr>
        </table>
        <div style="margin: 20px 0 10px 0;text-align: center;">
          <a-button v-if="!isShowAll" @click="showAll">查看全部</a-button>
          <a-button v-if="isShowAll" @click="showMain">收起</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchList, fetchDetail, importFile } from "@/api/book";
export default {
  data() {
    return {
      menu: [],
      selectedId: "",
      loading: false,
      detailLoading: false,
      isShowAll: false,
      importLoading: false,
      isAdmin: false
    };
  },

  mounted() {
    if(localStorage.getItem("mobile") == "18066102324") {
      this.isAdmin = true;
    }else {
      this.isAdmin = false;
    }
    this.loading = true;
    fetchList({
      pageNum: 1,
      pageSize: 99,
    })
      .then((res) => {
        console.log("book res", res);

        if (Array.isArray(res.list)) {
          this.menu = res.list;
          if (res.list.length > 0) {
            this.selectedId = res.list[0].id;
          }
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },

  watch: {
    selectedId() {
      if (this.menu.length > 0 && this.selectedId) {
        const item = this.menu.find((item) => item.id === this.selectedId);
        const index = this.menu.findIndex(
          (item) => item.id === this.selectedId
        );

        this.detailLoading = true;
        fetchDetail({
          id: this.selectedId,
        })
          .then((res) => {
            console.log("book detail", res);

            const list = Array.isArray(res.bimItemList) ? res.bimItemList : [];
            const showList = list.filter(v=>v.displayLevel === 'A');
            this.menu.splice(index, 1, {
              ...item,
              list: showList,
            });
            this.isShowAll = false;
          })
          .finally(() => {
            this.detailLoading = false;
          });
      }
    },
  },
  computed: {
    list() {
      if (this.menu.length > 0 && this.selectedId) {
        const list = this.menu.find((item) => item.id === this.selectedId).list;
        return list ? list : [];
      } else {
        return [];
      }
    },
  },

  methods: {
    onClick() {
      this.$refs.uploader.click();
    },

    onChange(e) {
      const files = e.target.files;
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append("jsonFile", files[i]);
      }

      this.importLoading = true;

      importFile(data)
        .then(() => {
          this.loading = true;
          fetchList({
            pageNum: 1,
            pageSize: 99,
          })
          .then((res) => {
            console.log("book res", res);

            if (Array.isArray(res.list)) {
              this.menu = res.list;
              if (res.list.length > 0) {
                this.selectedId = res.list[0].id;
              }
            }
          })
          .finally(() => {
            this.loading = false;
          });
        })
        .finally(() => {
          this.importLoading = false;
          e.target.value = null;
        });
    },
    showMain() {
      if (this.menu.length > 0 && this.selectedId) {
        const item = this.menu.find((item) => item.id === this.selectedId);
        const index = this.menu.findIndex(
          (item) => item.id === this.selectedId
        );
        this.detailLoading = true;
        fetchDetail({
          id: this.selectedId,
        })
          .then((res) => {
            console.log("book detail", res);

            const list = Array.isArray(res.bimItemList) ? res.bimItemList : [];
            const showList = list.filter(v=>v.displayLevel === 'A');
            this.menu.splice(index, 1, {
              ...item,
              list: showList,
            });
            this.isShowAll = false;
          })
          .finally(() => {
            this.detailLoading = false;
          });
      }
    },
    showAll() {
      if (this.menu.length > 0 && this.selectedId) {
        const item = this.menu.find((item) => item.id === this.selectedId);
        const index = this.menu.findIndex(
          (item) => item.id === this.selectedId
        );
        this.detailLoading = true;
        fetchDetail({
          id: this.selectedId,
        })
          .then((res) => {
            console.log("book detail", res);

            const list = Array.isArray(res.bimItemList) ? res.bimItemList : [];
            this.menu.splice(index, 1, {
              ...item,
              list,
            });
            this.isShowAll = true;
          })
          .finally(() => {
            this.detailLoading = false;
          });
      }
    }
  },
};
</script>

<style lang="less" scoped>
.panel {
  padding-left: 20px;
  display: flex;
  gap: 20px;

  .title {
    display: flex;
    align-items: center;
    color: #0562fd;
    font-size: 18px;
    font-weight: 600;
    line-height: 1em;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(207, 220, 238, 0.71);

    .icon {
      margin-top: 1px;
      height: 0.8em;
      width: 4px;
      margin-right: 8px;
      background-color: #0562fd;
    }

    .primary-button {
      margin-left: auto;
      display: block;
      color: #fff;
      font-size: 13px;
      line-height: 1em;
      padding: 10px 16px;
      background: linear-gradient(146deg, #0c99ff 0%, #0560fd 100%);
      box-shadow: 0px 5px 8px 0px rgba(5, 96, 253, 0.32);
      border-radius: 20px;
    }
  }

  .list {
    background: #fff;
    box-shadow: 0px 6px 26px 0px rgba(36, 80, 154, 0.15);
    border-radius: 20px;
    padding: 20px;
    
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      min-width: 240px;
      color: rgba(96, 112, 137, 0.69);
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(207, 220, 238, 0.71);

      img {
        height: 16px;
        display: block;
      }

      &:last-child {
        border-bottom-width: 0;
      }
    }

    .active {
      color: #0560fd;
    }

    .primary-button {
      margin-top: 16px;
      width: 100%;
      display: block;
      color: #fff;
      height: 38px;
      line-height: 100%;
      background: linear-gradient(146deg, #0c99ff 0%, #0560fd 100%);
      box-shadow: 0px 5px 8px 0px rgba(5, 96, 253, 0.32);
      border-radius: 20px;
    }
  }

  .card {
    flex: 1;
    background: #fff;
    box-shadow: 0px 6px 26px 0px rgba(36, 80, 154, 0.15);
    border-radius: 20px;
    padding: 20px;

    .table {
      width: 100%;
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      max-height: 67vh;
      overflow-x: hidden;
      overflow-y: auto;
      .header {
        opacity: 0.7;
        display: flex;
        align-items: center;

        td {
          flex-shrink: 0;
          padding: 16px;
          background: #ebf2fc;
          font-weight: 400;

          color: #9ca4b1;
          font-size: 16px;
        }
        td:first-child {
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;
        }
        td:last-child {
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
        }
      }

      .row {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(207, 220, 238, 0.71);

        td {
          padding: 16px;
        }
      }
    }
  }
}

.control {
  color: #0667fd;
}
#uploader {
  display: none;
}
</style>